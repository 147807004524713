@import "./blocks/mixins.scss";
@import "../common-styles/common-styles.scss";

@import "blocks/animations.scss";
@import "blocks/sd-element.scss";
@import "blocks/sd-question.scss";
@import "blocks/sd-error.scss";
@import "blocks/sd-collapsed-element.scss";
@import "blocks/sd-complex-element.scss";
@import "blocks/sd-input.scss";
@import "blocks/sd-comment.scss";
@import "blocks/sd-panel.scss";
@import "blocks/sd-signaturepad.scss";
@import "blocks/sd-checkbox.scss";
@import "blocks/sd-matrixdynamic.scss";
@import "blocks/sd-table.scss";
@import "blocks/sd-page.scss";
@import "blocks/sd-row.scss";
@import "blocks/sd-title.scss";
@import "blocks/sd-description.scss";
@import "blocks/sd-item.scss";
@import "blocks/sd-selectbase.scss";
@import "blocks/sd-checkbox.scss";
@import "blocks/sd-radio.scss";
@import "blocks/sd-visuallyhidden.scss";
@import "blocks/sd-matrix.scss";
@import "blocks/sd-rating.scss";
@import "blocks/sd-ranking.scss";
@import "blocks/sd-dropdown.scss";
@import "blocks/sd-tagbox.scss";
@import "blocks/sd-imagepicker.scss";
@import "blocks/sd-image.scss";
@import "blocks/sd-html.scss";
@import "blocks/sd-expression.scss";
@import "blocks/sd-progress.scss";
@import "blocks/sd-boolean.scss";
@import "blocks/sd-paneldynamic.scss";
@import "blocks/sd-file.scss";
@import "blocks/sd-hidden.scss";
@import "blocks/sd-navigation.scss";
@import "blocks/sd-button.scss";
@import "blocks/sd-body.scss";
@import "blocks/sd-multipletext.scss";
@import "blocks/sd-action.scss";
@import "blocks/sd-action-bar.scss";
@import "blocks/sd-context-btn.scss";
@import "blocks/sd-completedpage.scss";
@import "blocks/sd-progress-buttons.scss";
@import "blocks/sd-progress-toc.scss";
@import "blocks/sd-list.scss";
@import "blocks/sd-timer.scss";
@import "blocks/header.scss";
@import "blocks/sd-loading-indicator.scss";
@import "../components-container.scss";
@import "../signaturepad.scss";
@import "./defaultV2.m600.scss";

body {
  --sv-defaultV2-mark: true;
}

.sd-root-modern {
  -webkit-font-smoothing: antialiased;
  --sd-mobile-width: 600px;
  --sd-timer-size: calc(18 * #{$base-unit});
  width: 100%;
  font-family: $font-family;
  background-color: $background-dim;
  position: relative;

  @include scrollRules;

  form {
    // z-index: 1;
    position: relative;
  }
}

.sd-root-modern--animation-disabled * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.sd-root-modern.sd-root-modern--full-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

.sd-root-modern--mobile {
  --sd-timer-size: calc(9 * #{$base-unit});
}

.sd-root-modern__wrapper {
  position: relative;
}

.sd-root-modern__wrapper--has-image {
  min-height: 100%;
}

.sd-root-modern--full-container .sd-root-modern__wrapper--fixed {
  position: static;
  width: 100%;
  height: 100%;

  form {
    overflow: auto;
    width: 100%;
    max-height: 100%;
  }
}

.sv-popup .sv-popup__scrolling-content {
  box-sizing: content-box;
  @include scrollRules;
}

@mixin stickyHeader {
  position: sticky;
  top: 0;
}

.sv-components-container-center {
  @include stickyHeader;
}

.sv-root--sticky-top {
  .sv-components-container-center {
    z-index: 15;
  }
}

.sv-root--sticky-top.sd-progress--pages,
.sv-root--sticky-top.sd-progress--buttons {
  .sv-components-container-center {
    background-color: $background-dim;
    box-shadow: $shadow-medium, $shadow-small;
  }
}
.sv-components-container-right,
.sv-components-container-left {
  width: fit-content;
}
