.sd-imagepicker {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border: none;
  gap: calcSize(2);
  width: 100%;
  margin: 0;
}

.sd-imagepicker--column {
  align-items: flex-start;
  flex-direction: column;
}

@supports not (aspect-ratio: 1 / 1) {
  .sd-imagepicker>div {
    margin-right: calcSize(2);
  }
}

.sd-imagepicker__item {

  img,
  .sd-imagepicker__image-container>div {
    border-radius: calcCornerRadius(1);
    background-color: $background-dim-light;
  }
}

.sd-imagepicker__item-decorator {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sd-imagepicker__label {
  position: relative;

  .sd-visuallyhidden {
    height: 100%;
    margin: 0;
  }
}

.sd-imagepicker__image-container {
  position: relative;
}

.sd-imagepicker__check-decorator {
  display: block;
  opacity: 0;
  position: absolute;
  top: calcSize(1);
  right: calcSize(1);
  padding: calcSize(1.5);
  box-sizing: border-box;
  border-radius: 100%;
  background-color: $background;
  z-index: 1;
  transition: opacity $transition-duration;
}

.sd-imagepicker__check-icon {
  @include useEditorFontSize;
  display: block;
  width: multiply(1.5, $font-editorfont-size);
  height: multiply(1.5, $font-editorfont-size);
  fill: $primary;
}

.sd-imagepicker__item--checked .sd-imagepicker__check-decorator {
  opacity: 1;
}

.sd-imagepicker__item--error .sd-imagepicker__image-container::before {
  display: block;
  position: absolute;
  content: " ";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $red-light;
  border-radius: calcCornerRadius(1);
  background: linear-gradient(0deg, $red-light, $red-light);
}

.sd-imagepicker__item:focus-within .sd-imagepicker__image,
.sd-imagepicker__item--allowhover:not(.sd-imagepicker__item--readonly, .sd-imagepicker__item--preview) {
  .sd-imagepicker__image:hover {
    opacity: 0.5;
  }
}

.sd-imagepicker__image {
  display: block;
  box-sizing: border-box;
  max-width: 100%;
  transition: opacity $transition-duration;
}

.sd-imagepicker__text {
  @include useEditorFontSize;
  font-size: $font-editorfont-size;
  line-height: multiply(1.5, $font-editorfont-size);
  margin-top: calcSize(1);
  color: $font-questiontitle-color;
}

.sd-imagepicker__no-image {
  display: flex;
  background-color: $background-dim-light;
  inset-block-start: 0;
}

.sd-imagepicker__no-image-svg {
  height: calcSize(6);
  width: calcSize(6);
  margin: auto;

  use {
    fill: $foreground-light;
    opacity: 0.5;
  }
}

.sd-imagepicker__column {
  display: flex;
  flex-direction: column;
  gap: calcSize(2);
  align-items: flex-start;
  padding-right: 0;

  &.sd-selectbase__column {
    min-width: 0;
  }

  .sd-imagepicker__item {
    width: 100%;
  }

  .sd-imagepicker__text {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.sd-selectbase__column.sd-imagepicker__column:not(:last-child) {
  padding-right: 0;
}

.sd-imagepicker__item--readonly {
  .sd-imagepicker__check-icon {
    fill: $foreground;
  }
}

.sd-imagepicker__item--preview {
  .sd-imagepicker__check-decorator {
    display: none;
  }

  .sd-imagepicker__image {
    /* Safari 6.0 - 9.0 */
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.25;
  }

  &.sd-imagepicker__item--checked {
    .sd-imagepicker__image {
      /* Safari 6.0 - 9.0 */
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      opacity: 1;
    }
  }
}