.draggable-handle {
  margin-right: 8px;
}

.list-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  text-transform: uppercase;
}

.add-new-question {
  all: unset;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: #4181ff;
  user-select: none;
}

.question-answers-list {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.question-list {
  .picked-question-card {
    border: 1px solid #e7e7e7;
    margin-bottom: 16px;
  }
}

.picked-question-card {
  position: relative;
  background: #fafafa;
  border-radius: 4px;
  border-bottom: 1px dashed #e7e7e7;

  .content-area {
    padding: 16px;

    .question-content {
      display: flex;
      align-items: center;
    }

    /*
    .question-description {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
    */

    .question-type {
      background: rgba(65, 129, 255, 0.12);
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
      gap: 6px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }

    .options-label {
      margin-top: 16px;
      margin-bottom: 8px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    .choices-list {
      flex-wrap: wrap;
      gap: 6px;
    }
    .option {
      padding: 4px 12px;
      gap: 6px;

      height: 24px;

      background: rgba(65, 129, 255, 0.12);
      border-radius: 12px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .card-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 12px;
    height: 24px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid #d9d9d9;
      border-radius: 5px;
      background: transparent;

      padding: 4px;
      margin: 0;

      .delete-action {
        border: 1px solid rgba(249, 72, 72, 0.24);
        border-radius: 5px;
      }
    }
  }

  hr {
    border: 1px dashed #d9d9d9;
    margin: 0;
  }
}

.question-library {
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;

  .question {
    height: 68px;

    padding: 9px 12px;
    align-self: stretch;

    display: flex;

    &:not(:last-of-type) {
      border-bottom: 1px dashed #d9d9d9;
    }

    &:hover {
      background: #fafafa;
    }

    .question-number {
      width: 40px;
      height: 32px;

      background: #f5f5f5;

      margin-right: 8px;
    }

    .question-content {
      color: rgba(0, 0, 0, 0.72);
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }

    .question-type {
      color: rgba(0, 0, 0, 0.48);
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .question-actions {
      display: none;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 21px 12px;
      gap: 12px;

      width: 84px;
      min-height: 52px;
      max-height: 66px;

      position: absolute;
      right: 15px;
      background: #fafafa;
      translate: -2px -8.5px;
    }

    &:hover {
      .question-actions {
        display: flex;
      }
    }
  }
}

.search-field {
  border: 1px solid #b2b2b2;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* System/Black */
  color: #000000;

  padding: 7px 8px;

  width: 100%;
}

.question-modal {
  .modal-dialog {
    max-width: 673px;
  }
  .modal-header-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #000000;
  }
}

.action-button {
  background: transparent;

  align-items: flex-start;
  padding: 4px;

  border-radius: 5px;
  border: 1px solid #d9d9d9;

  &[disabled] {
    opacity: 0.4;
  }

  img {
    width: 16px;
    height: 16px;
  }
}

.red-border {
  border: 1px solid rgba(249, 72, 72, 0.24) !important;
}

.button {
  padding: 8px;
  background: #fafafa;
  border-width: 0px;
  margin-right: 16px;
  width: 150px;
}
.button-active {
  width: 150px;
  padding: 8px;
  background: #d7e9fe;
  border-width: 0px;
  border-radius: 6px;
  margin-right: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #4181ff;
}

.question-summary {
  margin-top: 16px;
  margin-bottom: 32px;
  hr {
    border: 1px dashed #d9d9d9;
    margin: 0;
  }
}

.total-responses {
  color: #98a0ae;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.card-margin {
  margin-top: 16px;
}

.summary-question-card {
  position: relative;
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 4px;

  .content-area {
    padding: 16px;

    .question-header {
      display: flex;
    }

    .question-content {
      display: flex;
      flex-direction: column;
      padding: 0px 16px;

      .recent-answers {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-transform: uppercase;
        color: #98a0ae;
        margin-bottom: 10px;
      }

      .answer-box {
        display: flex;
        padding: 12px 16px;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #e8eaed;
        background-color: #fff;
        margin-top: 12px;

        p {
          color: #121b2b;
        }
      }

      .chart-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .chart-style {
          width: 600px;
          height: 300px;
        }
      }
      .center-button {
        justify-content: center;
        align-items: center;
      }
    }

    .question-id {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      margin-right: 12px;
      background-color: #d7e9fe;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #0469dc;
      }
    }

    .question-description {
      h5 {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        max-width: 400px;
        color: #121b2b;
      }
      p {
        font-size: 13px;
        font-weight: 400;
        margin: 0px;
        color: #4d576a;
      }
    }

    .question-type {
      background: rgba(65, 129, 255, 0.12);
      border-radius: 12px;

      height: 20px;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 8px;
      gap: 6px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }

    .options-label {
      margin-top: 16px;
      margin-bottom: 8px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    .choices-list {
      flex-wrap: wrap;
      gap: 6px;
    }

    .option {
      padding: 4px 12px;
      gap: 6px;

      height: 24px;

      background: rgba(65, 129, 255, 0.12);
      border-radius: 12px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .card-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 12px;
    height: 24px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid #d9d9d9;
      border-radius: 5px;
      background: transparent;

      padding: 4px;
      margin: 0;

      .delete-action {
        border: 1px solid rgba(249, 72, 72, 0.24);
        border-radius: 5px;
      }
    }
  }

  hr {
    border: 1px solid #d9d9d9;
    margin: 0;
  }
}

.offcanvas {
  --bs-offcanvas-width: 600px;
}
