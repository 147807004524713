$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    colorText: #646777,
    colorTextAdditional: #646777,
    colorHover: #fafbfe,
    tableBorder: #eaeaea,
    colorBorder: #d8d9db,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #e7e7e7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #b4bfd0,
    colorFitness: #646777,
  ),
  dark: (
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    colorHover: #38373f,
    tableBorder: #333246,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-primary: #4ce1b6;
$color-primary-hover: darken($color-primary, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-primary: #0469dc;
$color-primary-hover: darken($color-primary, 10%);

$color-success: #399351;
$color-success-hover: darken($color-success, 10%);

$color-info: #87befd;
$color-info-hover: darken($color-info, 10%);

$color-warning: #ed9e45;
$color-warning-hover: darken($color-warning, 10%);

$color-danger: #eb4747;
$color-danger-hover: darken($color-danger, 10%);

$color-premium: #703ff3;
$color-premium-hover: darken($color-premium, 10%);

$color-secondary: #999999;
$color-secondary-hover: darken($color-secondary, 10%);

$color-green: #4ce1b6;
$color-green-hover: darken($color-green, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;

$color-disabled: #eee;

$color-icon: #98a0ae;
$color-title: #394356;
$color-text: #646777;

$color-border: rgba(0, 0, 0, 0.08);
