.affiliations {
  .affiliations-list {
    max-height: 260px;
    overflow-y: scroll;

    @media only screen and (max-width: 600px) {
      button {
        width: 200px !important;
      }
    }
    button {
      display: flex;
      transition-duration: 0.1s;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 40px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgb(212, 212, 212);
      outline: none;
      font-size: 16px;
    }

    button:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
