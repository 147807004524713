.notFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .card {
    max-height: 350px;
    max-width: 450px;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 48px;
  }
  h3 {
    margin-bottom: 10px;
  }

  button {
    margin: 0;
  }
}
