@import "../defaultV2-theme/mixins.scss";

.sd-completedpage,
.sd-completed-before-page {
  align-items: center;
  font-family: $font-family;
  font-weight: bold;
  box-sizing: border-box;
  text-align: center;
  height: auto;
  @include articleHtml;
}

.sd-completedpage:before,
.sd-completedpage:after {
  display: block;
  width: calcSize(9);
  margin-left: calc(50% - 4.5 * #{$base-unit});
  content: "";
}

.sd-completedpage:before {
  height: calcSize(13);
}

.sd-completedpage:after {
  height: calcSize(2);
}