.sd-root-modern,
.sd-container-modern {
  --sd-base-padding: calc(5 * #{$base-unit});
  --sd-base-vertical-padding: calc(4 * #{$base-unit});
  --sd-page-vertical-padding: calc(3 * #{$base-unit});
}

.sd-root-modern.sd-root-modern--mobile,
.sd-root-modern--mobile .sd-container-modern {
  --sd-base-padding: calc(3 * #{$base-unit});
  --sd-base-vertical-padding: calc(2 * #{$base-unit});
  --sd-page-vertical-padding: calc(2 * #{$base-unit});
  --sjs-mobile-font-editorfont-size: Max(16px, #{$font-default-editorfont-size});
}

.sd-root-modern {
  &.sd-root-modern--mobile {
    .sd-element__num {
      @include num_inline();
    }

    // .sd-element__title--expandable:before {
    //   inset-inline-start: calcSize(-2.5);
    // }

    .sd-element__title-expandable-svg {
      inset-inline-start: calcSize(-2.5);
    }

    .sd-title.sd-container-modern__title {
      flex-direction: column;
    }

    .sd-header__text {
      min-width: 100%;
    }
  }
}

.sd-multipletext--mobile {
  .sd-multipletext__cell {
    display: block;

    &:not(:first-of-type) {
      padding-left: 0;
      padding-top: calcSize(1);
    }

    :not(:last-of-type) {
      padding-bottom: calcSize(1);
    }
  }

  .sd-multipletext__item-container {
    padding-top: calcSize(1);
    padding-bottom: calcSize(1);
  }

  .sd-multipletext__item-title {
    max-width: none;
    border-right: none;
    width: 100%;
    padding: calcSize(1) 0;
    margin: 0;
  }

  .sd-multipletext__item {
    flex-basis: 0;
    min-width: 0;

    .sd-input {
      padding: 0;
      margin: 0;
    }
  }

  .sd-multipletext__item-container--answered,
  .sd-multipletext__item-container--allow-focus:focus-within {
    flex-direction: column;

    .sd-multipletext__item-title {
      padding: 0;

      span {
        font-size: calcFontSize(0.75);
        line-height: calcLineHeight(1);
      }
    }

    .sd-multipletext__item {
      flex-basis: auto;
      min-width: auto;
      width: 100%;

      .sd-input {
        width: 100%;
      }
    }
  }
}

.sd-selectbase--mobile .sd-selectbase--multi-column {
  flex-direction: column;

  .sd-selectbase__column:not(:last-child) {
    padding-right: 0;
  }
}