.sd-element {
  padding-left: var(--sv-element-add-padding-left, 0px);
  padding-right: var(--sv-element-add-padding-right, 0px);
}

.sd-element__title {
  outline: none;
}

.sd-element__title.sd-element__title--disabled {
  opacity: 0.25;
}

.sd-root--readonly .sd-element__title.sd-element__title--disabled {
  opacity: 1;
}

.sd-element--invisible {
  opacity: 0.35;
}

.sd-title.sd-element__title {
  font-family: $font-questiontitle-family;
  font-weight: $font-questiontitle-weight;
  color: $font-questiontitle-color;
}

.sd-element__header {
  .sv-string-editor {
    max-width: 100%;
    white-space: normal;
  }
}

.sd-element__title {
  font-size: 0;
  line-height: 0;
  position: static;
  margin: 0;

  .sd-element__num {
    font-size: calcFontSize(0.75);
    line-height: calcLineHeight(1);
    color: $foreground-light;
  }

  span {
    font-size: $font-questiontitle-size;
    line-height: multiply(1.5, $font-questiontitle-size);
  }

  .sv-title-actions__title {
    font-size: 0;
    line-height: 0;
  }

  .sv-title-actions__title {
    white-space: nowrap;
  }

  .sv-string-viewer {
    white-space: normal;

    &.sv-string-viewer--multiline {
      white-space: pre-wrap;
    }
  }
}

.sd-element__num {
  float: left;
  padding-top: calcSize(0.625);
  padding-bottom: calcSize(0.375);
  padding-inline-start: 0;
  padding-inline-end: calcSize(1);
  width: calcSize(5);
  font-size: calcFontSize(0.75);
  line-height: calcLineHeight(1);
  color: $foreground-light;
  margin-inline-start: calcSize(-5); // margin-left
  text-align: end;
  box-sizing: border-box;
  white-space: nowrap;
  flex-shrink: 0;
}

.sd-page__num+span,
.sd-element__num+span {
  float: left;
  width: 0;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sd-element__num {
    float: right;
  }

  .sd-element__title--collapsed .sd-element__title-expandable-svg {
    transform: rotate(180deg);
  }
}

.sd-element__title--num-inline {
  .sd-element__num {
    @include num_inline();
  }
}

.sd-element__title--expandable.sd-element__title--expandable {
  position: relative;
  display: block;
}

.sd-element__title-expandable-svg {
  display: inline-block;
  width: var(--lbr-question-panel-expand-button-icon-width, calcSize(2));
  height: var(--lbr-question-panel-expand-button-icon-height, calcSize(2));

  position: absolute;
  inset-inline-start: calcSize(-3);
  top: calcSize(0.5);

  use {
    fill: $foreground-light;
  }
}

// .sd-element__title--expandable.sd-element__title--expanded:before {
//   transform: rotate(90deg);
// }

//with borders styles
.sd-element--with-frame {
  border-radius: $panel-corner-radius;
  box-sizing: border-box;
  padding-top: var(--sd-base-padding);
  padding-left: calc(var(--sd-base-padding) + var(--sv-element-add-padding-left, 0px));
  padding-right: calc(var(--sd-base-padding) + var(--sv-element-add-padding-right, 0px));
  padding-bottom: var(--sd-base-padding);
  background: $question-background;
  box-shadow: $shadow-small;
}

//compact styles
.sd-element--with-frame.sd-element--compact {
  border: 1px solid $border;
  border-radius: $panel-corner-radius;
  box-shadow: none;
  background-color: transparent;
}

@keyframes elementMoveIn {
  from {
    flex-basis: 0;
    flex-grow: 0;
    max-width: 0;
    min-width: 0;
    width: 0;
    height: 0;
    padding-left: 0;
    overflow: hidden;
  }

  to {
    height: var(--animation-height);
    flex-basis: var(--animation-width);
    width: var(--animation-width);
    min-width: var(--animation-width);
    padding-left: var(--animation-padding-left);
    overflow: visible;
  }
}

.sd-element-wrapper--enter {
  animation-name: elementMoveIn, fadeIn;
  animation-timing-function: $ease-out;
  animation-fill-mode: forwards;
  animation-duration: $element-move-in-duration, $element-fade-in-duration;
  animation-delay: 0s, $element-fade-in-delay;
  opacity: 0;
}

.sd-element-wrapper--leave {
  animation-name: elementMoveIn, fadeIn;
  animation-timing-function: $reverse-ease-out;
  animation-fill-mode: forwards;
  animation-direction: reverse;
  animation-duration: $element-move-out-duration, $element-fade-out-duration;
  animation-delay: $element-move-out-delay, 0s;
}

.sd-element__content {
  box-sizing: border-box;
}

.sd-element__content--enter,
.sd-element__content--leave {
  --animation-padding-top: 0;
  --animation-padding-bottom: 0;
}

.sd-element__content--enter {
  animation-name: fadeIn, moveInWithOverflow;
  min-height: 0 !important;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: $ease-out;
  animation-duration: $expand-fade-in-duration, $expand-move-in-duration;
  animation-delay: $expand-fade-in-delay, 0s, 0s;
}

.sd-element__content--leave {
  animation-name: fadeIn, moveInWithOverflow;
  min-height: 0 !important;
  animation-direction: reverse;
  animation-fill-mode: forwards;
  animation-timing-function: $reverse-ease-out;
  animation-duration: $collapse-fade-out-duration, $collapse-move-out-duration;
  animation-delay: 0s, $collapse-move-out-delay, $collapse-move-out-delay;
}

.sd-element--expandable.sd-elemenet--expandable--animating {

  &>.sd-element__header:focus-within,
  &>.sd-element__header:hover {
    background-color: transparent;
  }
}

.sd-elemenet--expandable--animating.sd-element--expandable {
  transition-property: padding-top, padding-bottom;

  &>.sd-element__header {
    transition-property: padding-top, padding-bottom;
  }

  &.sd-element--expanded {
    transition-timing-function: $ease-out;
    transition-duration: $expand-move-in-duration;

    &>.sd-element__header {
      transition-timing-function: $ease-out;
      transition-duration: $expand-move-in-duration;

      .sd-element__title:before {
        transition-duration: $expand-move-in-duration;
      }
    }
  }

  &.sd-element--collapsed {
    transition-timing-function: $ease-out;
    transition-duration: $collapse-move-out-duration;
    transition-delay: $collapse-move-out-delay;

    &>.sd-element__header {
      transition-timing-function: $ease-out;
      transition-duration: $collapse-move-out-duration;
      transition-delay: $collapse-move-out-delay;

      .sd-element__title:before {
        transition-duration: $collapse-move-out-duration;
        transition-delay: $collapse-move-out-delay;
      }
    }
  }

  &.sd-element--complex:not(.sd-question--empty) {
    &>.sd-element__header--location-top:after {
      display: block;
      opacity: 0;
      height: 0;
      --animation-height: 1px;
      animation-name: fadeIn, moveIn;
      animation-fill-mode: forwards;
      animation-timing-function: $ease-out;
      animation-delay: $expand-fade-in-delay, 0s;
      animation-duration: $expand-fade-in-duration, $expand-move-in-duration;
    }

    &.sd-element--collapsed .sd-element__header--location-top:after {
      animation-direction: reverse;
      opacity: 1;
      height: 1px;
      animation-timing-function: $reverse-ease-out;
      animation-delay: 0s, $collapse-move-out-delay;
      animation-duration: $collapse-fade-out-duration, $collapse-move-out-duration;
    }
  }
}