.sd-element--expanded,
.sd-element--collapsed {
  &>.sd-element__header {
    cursor: pointer;
  }
}

.sd-element--collapsed {
  &>.sd-element__header {
    padding: calcSize(0) var(--sd-base-padding);
    box-sizing: border-box;
    background-color: $background;
    margin-inline-start: calc(-1 * var(--sd-base-padding));
    width: calc(100% + 2 * var(--sd-base-padding));
  }

  &.sd-element--with-frame {
    padding-top: 0;
    padding-bottom: 0;

    &>.sd-element__header {
      padding-top: calcSize(2);
      padding-bottom: calcSize(2);
      border-radius: $panel-corner-radius;
    }
  }

  &>.sd-element__header:focus-within,
  &>.sd-element__header:hover {
    background-color: $background-dim-light;
  }

  &.sd-element--nested {

    &>.sd-element__header:hover,
    &>.sd-element__header:focus-within {
      box-shadow: 0 calcSize(-2) 0 0 $background-dim-light, 0 calcSize(2) 0 $background-dim-light;
    }
  }
}