.dashboard {
  //default

  .dashboard__visitors-chart-title {
    span {
      color: $color-additional;
      font-size: 11px;
    }
  }

  .dashboard__visitors-chart-number {
    font-size: 48px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .dashboard__audience-table {
    .progress {
      margin-top: 10px;
    }

    .progress-bar {
      height: 10px;
    }
  }

  .dashboard__table-flag {
    width: 42px;
    height: 30px;
    margin-right: 25px;
  }

  .dashboard__bounce-percent {
    color: $color-primary;
    font-size: 48px;
    line-height: 48px;
    margin-top: 20px;
    margin-bottom: 45px;
  }

  //commerce

  .dashboard__product-img {
    max-height: 270px;
    height: 100%;
    padding: 10px 0 20px 0;
    margin-bottom: 15px;
    text-align: center;

    @include themify($themes) {
      border-bottom: 1px solid themed('colorBorder');
    }

    img {
      width: auto;
      max-width: 100%;
      max-height: 240px;
    }
  }

  .dashboard__product-title {
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .dashboard__product-subhead {
    font-size: 13px;
    line-height: normal;
  }

  .dashboard__table-orders {
    overflow: hidden;
    min-width: 430px;

    tbody td {
      padding: 16px 10px;
    }

    .dashboard__table-orders-title {
      position: relative;
      padding-left: 52px;
      display: flex;
      line-height: 15px;
      align-items: center;
    }
  }

  .dashboard__table-orders-total {
    white-space: nowrap;
  }

  .dashboard__table-orders-img-wrap {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    left: 10px;
  }

  .dashboard__table-orders-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .dashboard__table-orders-amount {
    display: flex;

    div {
      width: 3px;
      height: 14px;
      display: inline-block;
      background-color: $color-primary;
      border-radius: 3px;
      margin: auto 3px auto 0;
    }

    span {
      color: $color-additional;
      margin: auto 0 auto 5px;
    }

    &.dashboard__table-orders-amount--medium {
      div {
        background-color: $color-warning;
      }
    }

    &.dashboard__table-orders-amount--low {
      div {
        background-color: $color-danger;
      }
    }
  }

  .dashboard__table-more {
    display: flex;

    & > button {
      margin: 0;
      padding: 0 5px;
      border: none;
      background-color: transparent;
      margin-left: auto;

      &:before {
        display: none;
      }

      svg {
        margin: 0;
        height: 20px;
        width: 20px;
        fill: $color-additional;
        transition: all 0.3s;
      }

      &:hover,
      &:not([disabled]):not(.disabled):active,
      &:focus {
        background: transparent;

        svg {
          fill: $color-primary;
        }
      }
    }

    & > div {
      min-width: 90px;
      width: 100%;
    }

    button {
      font-size: 13px;
    }
  }

  .dashboard__sales-toggle {
    label {
      margin: 0;

      &:after {
        box-shadow: none;
        background-color: $color-danger;
      }
    }
  }

  .dashboard__sales-toggle-left {
    margin-left: auto;
    margin-right: 12px;
    color: $color-additional;
    pointer-events: none;
  }

  .dashboard__sales-toggle-right {
    margin-right: auto;
    margin-left: 12px;
    color: $color-additional;
    pointer-events: none;
  }

  .dashboard__sales-report {
    display: flex;

    & > div {
      width: 100%;
      margin-right: 25px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    p {
      margin-top: 0;
    }
  }

  .dashboard__sales-report-title {
    color: $color-additional;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 10px;
  }

  .dashboard__sales-report-now {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .dashboard__sales-report-plan {
    font-size: 11px;
    line-height: 15px;
    color: $color-additional;
    opacity: 0.5;
    margin-bottom: 10px;
  }

  .dashboard__sales-report-value {
    margin-bottom: 0;
  }

  //fitness

  .dashboard__weight-stats {
    display: flex;
    margin-bottom: 20px;
  }

  .dashboard__weight-stat {
    margin-right: 40px;

    @media screen and (min-width: 768px) {
      margin-right: 80px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .dashboard__weight-stat-title {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .dashboard__weight-stat-value {
    font-size: 36px;
    line-height: 48px;

    @media screen and (min-width: 768px) {
      font-size: 48px;
      line-height: 62px;
    }

    &.dashboard__weight-stat-value--control {
      color: $color-warning;
    }

    &.dashboard__weight-stat-value--total {
      color: $color-danger;
    }
  }

  .dashboard__health-chart-card {
    text-align: center;
  }

  .dashboard__goal {
    font-size: 12px;
    color: $color-additional;
  }

  .dashboard__health-chart {
    position: relative;
  }

  .dashboard__health-chart-info {
    position: absolute;
    width: 100%;
    top: calc(50% - 55px);
    animation: label 1.5s ease-in;

    p {
      margin: 0;
    }
  }

  .dashboard__health-chart-number {
    font-size: 56px;
    line-height: 60px;

    @include themify($themes) {
      color: themed('colorFitness');
    }
  }

  .dashboard__health-chart-units {
    color: $color-additional;
    margin-top: 5px;
  }

  .dashboard__competitor {
    display: flex;
    padding: 10px 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: calc(100% + 65px);
      top: 0;
      left: -30px;
      z-index: 0;
      opacity: 0;
      transition: all 0.3s;

      @include themify($themes) {
        background: themed('colorHover');
      }
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  .dashboard__competitor-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    min-width: 40px;
    z-index: 1;

    img {
      height: 100%;
      min-width: 100%;
    }
  }

  .dashboard__competitor-info {
    z-index: 1;
  }

  .dashboard__competitor-name {
    font-weight: 500;
  }

  .dashboard__competitor-result {
    color: $color-danger;
    margin-top: 0;
    font-size: 14px;
  }

  // > 2

  .dashboard__area {
    .recharts-legend-wrapper {
      bottom: 0 !important;
    }
  }

  .dashboard__total {
    display: flex;
    margin-top: -15px;

    &.dashboard__total--area {
      margin-top: -55px;
    }
  }

  .dashboard__chart-container {
    max-width: 180px;
  }

  .dashboard__total-stat {
    font-size: 18px;
    height: 24px;
    margin-right: auto;
    margin-top: auto;
    padding-right: 10px;
    line-height: 24px;
  }

  .dashboard__trend-icon {
    fill: $color-primary;
    height: 24px;
    width: 24px;
    margin-right: 3px;
    min-width: 24px;
    margin-top: auto;
  }

  .dashboard__stat {
    display: flex;
    margin-top: 40px;
    margin-bottom: 10px;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .dashboard__stat-info {
      margin: auto 0 auto 30px;

      p {
        color: $color-additional;
      }
    }

    .dashboard__stat-number {
      margin-top: 10px;
    }

    .dashboard__stat-chart {
      position: relative;
      text-align: center;
    }

    .dashboard__stat-label {
      position: absolute;
      font-size: 18px;
      line-height: 20px;
      top: calc(50% - 10px);
      width: 100%;
      margin: 0;
      animation: label 1.5s ease-in;
      color: #dddddd;
    }

    &.dashboard__stat--budget {
      text-align: center;
      flex-wrap: wrap;

      .dashboard__stat-chart {
        margin: auto;
      }

      .dashboard__stat-label {
        font-size: 36px;
      }

      .dashboard__stat-main {
        width: 100%;

        hr {
          margin-bottom: 30px;
          margin-top: 40px;
        }
      }

      .dashboard__stat-main-title {
        color: $color-additional;
      }

      .dashboard__stat-main-number {
        color: $color-danger;
        font-size: 48px;
        line-height: 34px;
        margin-top: 15px;
      }

      .dashboard__stat-data {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 10px;

        p {
          margin: 0;
        }
      }

      .dashboard__stat-data-number {
        font-size: 18px;
        line-height: 34px;
        font-weight: 500;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1439px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;

      .dashboard__stat-info {
        margin: 0;
      }

      .dashboard__stat-chart {
        margin-bottom: 15px;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1539px) {
      .dashboard__stat-chart {
        svg,
        div {
          width: 80px !important;
          height: 80px !important;
        }
      }
    }
  }

  @keyframes label {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .dashboard__chart-pie {
    height: 200px !important;

    .recharts-surface {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 768px) {
      &.dashboard__chart-pie--crypto {
        height: 300px !important;

        .dashboard__chart-pie-container {
          height: 300px !important;
          padding-left: 60px;
        }
      }

      &.dashboard__chart-pie--commerce {
        height: 360px !important;

        .dashboard__chart-pie-container {
          height: 360px !important;
          padding-left: 60px;
        }
      }

      &.dashboard__chart-pie--fitness {
        height: 360px !important;

        .dashboard__chart-pie-container {
          height: 360px !important;
          padding-left: 90px;
        }
      }
    }

    @media screen and (max-width: 480px) {
      &.dashboard__chart-pie--crypto {
        height: 180px !important;
        padding-left: 10px;

        .dashboard__chart-pie-container {
          height: 180px !important;
          padding-left: 40px;
        }

        .dashboard__chart-legend {
          margin-left: -10px;

          li {
            margin-top: 0;
          }
        }
      }
    }
  }

  .dashboard__chart-pie-container {
    padding-left: 0;
    height: 200px !important;

    @media screen and (min-width: 768px) {
      padding-left: 40px;
    }
  }

  .dashboard__chart-legend {
    padding: 0;
    list-style: none;

    span {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    li {
      margin-top: 5px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  .dashboard__card-widget {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  //crypto

  .dashboard__btc {
    color: #f6a81e;
  }

  .dashboard__eth {
    color: #5e62e6;
  }

  .dashboard__neo {
    color: #3ddb42;
  }

  .dashboard__ste {
    color: #21cbe6;
  }

  .dashboard__eos {
    color: #6d6a6a;
  }

  .dashboard__lit {
    color: #b7b3b3;
  }

  .dashboard__table-crypto {
    tbody td {
      padding: 17px 10px;
      white-space: nowrap;
    }

    .dashboard__table-crypto-chart {
      padding: 10px;
      width: 180px;
    }

    .dashboard__chart-container {
      max-width: 180px;
    }
  }

  .dashboard__place-order {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: 900px) {
      flex-wrap: nowrap;
    }

    @media screen and (min-width: 1200px) {
      flex-wrap: wrap;
    }

    @media screen and (min-width: 1360px) {
      flex-wrap: nowrap;
    }
  }

  .dashboard__place-order-form {
    position: relative;
    width: 100%;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    form {
      padding: 20px 20px 30px 20px;
      border-radius: 5px;
      margin-top: 10px;

      @include themify($themes) {
        background-color: themed('colorBorder');
      }
    }

    .form__form-group-label {
      width: 50px;
    }

    .form__form-group-field {
      width: calc(100% - 50px);
    }

    .form__button-toolbar {
      margin-left: 0;
      width: 100%;

      button {
        width: 100%;
      }
    }

    input {
      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }

    @media screen and (min-width: 900px) {
      width: calc(50% - 15px);
      margin-top: 0;
    }

    @media screen and (min-width: 1200px) {
      width: 100%;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (min-width: 1360px) {
      width: calc(50% - 15px);
      margin-top: 0;
    }
  }

  .dashboard__place-order-form-subhead {
    position: absolute;
    font-size: 12px;
    top: 4px;
    right: 0;
  }

  .dashboard__chart-pie.dashboard__chart-pie--crypto {
    @media screen and (min-width: 1200px) {
      .dashboard__chart-pie-container {
        padding-left: 0;
      }

      .recharts-legend-wrapper {
        bottom: -110px !important;
      }
    }

    @media screen and (min-width: 1360px) {
      .dashboard__chart-pie-container {
        padding-left: 90px;
      }

      .recharts-legend-wrapper {
        bottom: 5px !important;
      }
    }
  }

  .dashboard__carousel {
    margin-bottom: -25px;

    .slick-dots {
      bottom: -35px;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      background: none;
      opacity: 0;
      pointer-events: none;
    }

    .dashboard__carousel-slide {
      border-radius: 5px;
      padding: 15px 13px;
      position: relative;
      overflow: hidden;

      svg {
        position: absolute;
        height: 80px;
        width: 80px;
        top: calc(50% - 40px);
        opacity: 0.3;
        right: -20px;

        @include themify($themes) {
          fill: themed('colorIcon');
        }
      }

      p {
        color: $color-additional;
        margin: 0;
      }

      .dashboard__carousel-title {
        color: $color-danger;
        font-size: 24px;
        font-weight: 500;
      }

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &.dashboard__carousel-slide--red {
        background: $color-danger;

        p {
          color: white;
          opacity: 0.45;
        }

        svg {
          fill: #ffffff;
        }

        .dashboard__carousel-title {
          opacity: 1;
        }
      }
    }
  }
  &-event,
  &-offer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #f4f4f6;
    cursor: pointer;
    &:hover {
      background: #f9fafb;
    }
    &-wrapper {
      display: flex;
      align-items: center;
    }
    &-calendar {
      width: 44px;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background: #f4f4f6;
      border-radius: 6px;
      border: 1px solid #e8eaed;
      &-month {
        text-align: center;
        width: 100%;
        color: #fff;
        font-weight: 700;
        letter-spacing: 1px;
        background: #0f7dfb;
        font-size: 10px;
        padding: 2px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        text-transform: uppercase;
      }
      &-day {
        color: #000;
        font-weight: 400;
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 20px;
        margin-top: -2px;
      }
    }
    &-content {
      margin-left: 16px;
      &-title {
        font-family: Inter;
        color: #121b2b;
        font-size: 16px;
        font-weight: 700;
      }
      &-date {
        color: #98a0ae;
        font-size: 13px;
        font-family: Inter;
        font-weight: 500;
        margin-top: -4px;
      }
    }
    &-participants {
      font-size: 13px;
      color: #98a0ae;
      text-align: right;
      font-family: Inter;
      font-weight: 700;
      &-count {
        color: #121b2b;
      }
    }
    &-progress {
      margin-top: 6px;
      display: flex;
      width: 100px;
      &-bar {
        background: #0f7dfb;
        height: 6px;
        border-radius: 10px;
      }
      &-indicator {
        background: #e8eaed;
        height: 6px;
        flex: 1;
        border-radius: 10px;
      }
    }
    &-creator-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1440px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    &-creator,
    &-validity {
      color: #98a0ae;
      font-size: 13px;
      font-family: Inter;
      font-weight: 500;
      margin-top: -4px;
      padding: 0 5px;
    }
    &-validity {
      @media screen and (max-width: 1440px) {
        margin-left: -5px;
      }
    }
    &-creator {
      margin-left: -5px;
    }
    &-divider {
      background: #98a0ae;
      width: 3px;
      height: 3px;
      border-radius: 100%;
      @media screen and (max-width: 1440px) {
        display: none;
      }
    }
    &-category {
      color: #121b2b;
      margin-top: -20px;
      background: #ebf4ff;
      padding: 2px 8px;
      border-radius: 12px;
      margin-right: -10px;
    }
  }
}
.widget-wrapper {
  border-radius: 6px !important;
  border: 1px solid #d7dae0;
  a {
    text-decoration: none;
  }
}
.widget-pane-wrapper {
  display: flex;
  min-height: 50vh;
}
.widget-pane {
  font-family: 'Inter';
  width: 50%;
  background: #d7dae0;
  border-radius: 4px;
  padding: 20px;
  margin: 10px;
  &-header {
    font-family: Inter;
    font-weight: 600;
    margin-bottom: 15px;
  }
}
.widget-dropzone {
  height: 100%;
}
.widget-modal-header {
  border-bottom: 1px solid #f4f4f6;
  &-text {
    font-family: Inter;
    font-weight: 700;
  }
}
.widget-modal-footer {
  border: none;
  padding: 0 20px;
  margin: 0 5px;
  &-btn {
    background: #4181ff !important;
    color: #fff !important;
  }
}
.available-widget,
.enabled-widget {
  display: flex;
  background: #fff;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #e8eaed;
  text-align: center;
  border-radius: 5px;
  font-family: 'Inter';
  img {
    width: 15px;
    margin-right: 10px;
  }
}
.available-widget-active,
.enabled-widget-active {
  background: #ebf4ff;
  margin: 5px;
  padding: 10px;
  border: 1px solid #0469dc;
  text-align: center;
  font-family: Inter;
  border-radius: 5px;
  img {
    width: 15px;
    margin-right: 10px;
  }
}
.widget-border {
  &-top {
    border-top: 1px solid #0469dc;
  }
  &-bottom {
    border-bottom: 1px solid #0469dc;
  }
  &-remove {
    border: none;
  }
}
.widget-modify {
  &-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &-btn {
    display: flex;
    align-items: center;
    background: transparent;
    box-shadow: none;
    outline: none;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 12px;
    color: #0469dc;
    border: 1px solid #cbcbce;
    background: transparent;
    margin-bottom: 10px;
    &:hover {
      background: #ebf4ff;
    }
    img {
      width: 12px;
      height: 12px;
      margin-bottom: 1px;
    }
    div {
      margin-left: 5px;
    }
  }
}
.events-link,
.offers-link {
  font-size: 14px;
}
.widget-header {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  color: #121b2b;
  align-items: center;
  margin-top: -10px;
  p {
    font-size: 18px;
    margin-bottom: 5px;
    color: #121b2b;
    font-family: Inter;
    font-weight: 700;
  }
  h5 {
    margin-top: -5px;
    font-size: 14px;
    color: #98a0ae;
    font-family: Inter;
  }
}
.navigation {
  list-style: none;
  border: 1px solid #e8eaed;
  border-radius: 3px;
  color: #98a0ae;
  cursor: pointer;
  & > li {
    margin-left: -14px;
    padding: 10px;
    border-radius: 3px;
    &:hover {
      background: #ebf4ff;
    }
  }
}
.navigation ul {
  display: none;
  position: absolute;
  margin-top: 10px;
  right: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  direction: rtl;
  border-radius: 3px;
  li {
    padding: 12px;
  }
  button {
    color: #828a9c;
    background: transparent;
    border: none;
  }
}
.navigation li:hover > ul {
  display: block;
  background: #fff;
}
.navigation li ul li:hover {
  background: #ebf4ff;
}
.empty-graph-data {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
}

.widget-dropdown {
  position: relative;
  display: inline-block;
}

.widget-dropdown-btn {
  border: 1px solid #e8eaed;
  color: #6c7689;
  background: #fff;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.widget-dropdown-content {
  display: none;
  position: absolute;
  background: #f9f9f9;
  overflow: auto;
  z-index: 1;
  max-height: 300px;
}

.widget-dropdown-content button {
  color: #6c7689;
  padding: 8px 12px;
  background-color: transparent;
  box-sizing: none;
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  display: block;
}

.widget-dropdown-content button:hover {
  background: #ebf4ff;
}

.widget-dropdown:hover .widget-dropdown-content {
  display: block;
}

.widget-dropdown:hover .widget-dropdown-btn {
  background: #ebf4ff;
}

.widget-dropdown-btn .dropdown-chevron {
  display: inline-block;
  transition: transform 0.3s;
}

.widget-dropdown:hover .widget-dropdown-btn .dropdown-chevron {
  transform: rotate(180deg);
}
