@import "../defaultV2-theme/mixins.scss";
.sd-html {
  white-space: initial;
}

.sd-html {
  @include articleHtml;
}

.sd-html--nested {
  color: $font-questiontitle-color;
}
