.sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  position: sticky;
  left: calcSize(-3);
  margin-left: calcSize(-3);
  z-index: 12;
}

.sd-question--mobile .sd-matrixdynamic__footer::before
{
  z-index: 12;
  content: " ";
  display: block;
  position: relative;
  height: 1px;
  background-color: $border-light;
  left: calcSize(-3);
  top: calcSize(-1);
  width: calc(100% + 6 * #{$base-unit});
  z-index: 12;
}

.sd-matrixdynamic__footer:first-child {
  padding-bottom: calcSize(2);
  &::before {
    display: none;
  }
}

.sd-matrixdynamic__footer {
  padding-top: calcSize(2);
}

.sd-action.sd-matrixdynamic__remove-btn {
  opacity: 0.5;
}

.sd-action.sd-matrixdynamic__remove-btn .sd-action__icon {
  @include useEditorFontSize;
  width: multiply(1.5, $font-editorfont-size);
  height: multiply(1.5, $font-editorfont-size);
}

.sd-matrixdynamic__btn {
  appearance: none;
  background: transparent;
  border: none;
  line-height: calcLineHeight(1.5);
  font-size: calcFontSize(1);
  font-family: $font-family;
  font-weight: 600;
  padding: calcSize(1) 0;
}

.sd-matrixdynamic__drag-element {
  padding: calcSize(2);

  &:hover {
    background-color: $background;
  }

  &:after {
    content: " ";
    display: block;
    height: calcSize(0.5);
    width: calcSize(2);
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: calcSize(1.25);
  }
}

.sd-matrixdynamic__placeholder {
  .sd-matrixdynamic__add-btn {
    margin-left: 0;
  }
}

.sd-table__row:hover {
  .sd-drag-element__svg {
    visibility: visible;
  }
}

.sd-table__cell.sd-table__cell--drag {
  >div {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: calcSize(-4);
    width: calcSize(4);
    background-color: $question-background;
    min-height: calcSize(6);
  }
}

.sd-drag-element__svg {
  width: calcSize(3);
  height: calcSize(3);
  display: block;
  cursor: pointer;
  visibility: hidden;

  use {
    fill: $primary;
  }
}

@keyframes borderAnimation {
  from {
    border-width: 0px;
  }

  to {
    border-width: 8px;
  }
}

@keyframes paddingAnimation {
  from {
    padding-top: 0;
    padding-bottom: 0;
  }

  to {
    padding-top: 24px;
    padding-bottom: 32px;
  }
}

@keyframes empty {
  from {}

  to {}
}

.sd-table__row--leave,
.sd-table__row--enter {
  animation-name: empty;
  --move-whole-animation-duration: calc(var(--move-animation-duration) + var(--move-animation-delay));
  --fade-whole-animation-duration: calc(var(--fade-animation-duration) + var(--fade-animation-delay));
  animation-duration: max(var(--fade-whole-animation-duration), var(--move-whole-animation-duration));

  &>td {
    animation-name: borderAnimation;
    animation-direction: var(--animation-direction);
    animation-timing-function: var(--animation-timing-function);
    animation-duration: var(--move-animation-duration);
    animation-fill-mode: forwards;
    animation-delay: var(--move-animation-delay);

    &>div {
      animation-name: fadeIn, moveInWithOverflow;
      opacity: 0;
      animation-direction: var(--animation-direction);
      animation-timing-function: var(--animation-timing-function);
      animation-fill-mode: forwards;
      animation-duration: var(--fade-animation-duration), var(--move-animation-duration);
      animation-delay: var(--fade-animation-delay), var(--move-animation-delay);
    }
  }
}

.sd-table__row--enter {
  --move-animation-delay: 0s;
  --move-animation-duration: #{$matrix-row-move-in-duration};
  --fade-animation-duration: #{$matrix-row-fade-in-duration};
  --fade-animation-delay: #{$matrix-row-fade-in-delay};
  --animation-direction: normal;
  --animation-timing-function: #{$ease-out};
}

.sd-table__row--leave {
  --move-animation-delay: #{$matrix-row-move-out-delay};
  --move-animation-duration: #{$matrix-row-move-out-duration};
  --fade-animation-duration: #{$matrix-row-fade-out-duration};
  --fade-animation-delay: 0s;
  --animation-direction: reverse;
  --animation-timing-function: #{$reverse-ease-out};
}

.sd-table__row--detail {

  &.sd-table__row--enter,
  &.sd-table__row--leave {
    &>td {
      animation-name: borderAnimation, paddingAnimation;
      animation-duration: var(--move-animation-duration);
      animation-fill-mode: forwards;
      animation-direction: var(--animation-direction);
      animation-timing-function: var(--animation-timing-function);
    }
  }

  &.sd-table__row--enter {
    --move-animation-delay: 0s;
    --move-animation-duration: #{$matrix-detail-row-move-in-duration};
    --fade-animation-duration: #{$matrix-detail-row-fade-in-duration};
    --fade-animation-delay: #{$matrix-detail-row-fade-in-delay};
    --animation-direction: normal;
    --animation-timing-function: #{$ease-out};
  }

  &.sd-table__row--leave {
    --move-animation-delay: #{$matrix-detail-row-move-out-delay};
    --move-animation-duration: #{$matrix-detail-row-move-out-duration};
    --fade-animation-duration: #{$matrix-detail-row-fade-out-duration};
    --fade-animation-delay: 0s;
    --animation-direction: reverse;
    --animation-timing-function: #{$reverse-ease-out};
  }
}