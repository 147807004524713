.sd-description {
  font-style: normal;
  font-family: $font-questiondescription-family;
  font-weight: $font-questiondescription-weight;
  color: $font-questiondescription-color;
  font-size: $font-questiondescription-size;
  line-height: multiply(1.5, $font-questiondescription-size);
  white-space: normal;
}

.sd-description.sd-question__description--under-input {
  padding-top: calc(0.375 * var(--sd-base-vertical-padding));
}

.sd-element__header .sd-description {
  margin-top: calc(0.25 * var(--sd-base-vertical-padding) - 0.5 * #{$base-unit});
}