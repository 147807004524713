.tab-pane div.row.packages-product-list {
  margin-bottom: 20px;
}

.packages-badge {
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bolder;
}

.package-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-bottom: 1px solid #dee2e6;
  padding: 10px;
  width: 100%;
  transition-duration: 0.1s;

  &.active {
    background-color: #4de1b6;
    border-color: white;
    p {
      color: white;
    }
  }

  p {
    margin: 0;
  }
}
