@keyframes rotationAnimation {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}

.sd-loading-indicator {
  height: calcSize(6);

  .sv-svg-icon {
    height: calcSize(6);
    width: calcSize(6);
    animation-name: rotationAnimation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;
  }
}