.sd-image__image {
  display: block;
  max-width: 100%;
  border-radius: calcCornerRadius(1);
}

.sd-image__image--adaptive {
  width: 100%;
  height: auto;
  max-width: calcSize(80);
}

.sd-image__no-image {
  background: $background-dim-light;
  min-width: calcSize(5);
  min-height: calcSize(27.5);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sd-image__no-image use {
  fill: $foreground-light;
  opacity: 0.5;
}

.sd-question--image {
  width: 100%;
}