@import "../defaultV2-theme/variables.scss";

.sv-save-data_root {
  position: fixed;
  left: 50%;
  bottom: calcSize(3);
  background: $background;
  opacity: 0;
  padding: calcSize(3) calcSize(6);
  box-shadow: $shadow-medium;
  border-radius: calcCornerRadius(2);
  color: $foreground;
  min-width: calcSize(30);
  text-align: center;
  z-index: 1600; //todo: should overlap popup

  font-family: $font-family;
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transform: translateX(-50%) translateY(calcSize(3));
  transition-timing-function: ease-in;
  transition-property: transform, opacity;
  transition-delay: 0.25s;
  transition: 0.5s;
}

.sv-save-data_root.sv-save-data_root--shown {
  transition-timing-function: ease-out;
  transition-property: transform, opacity;
  transform: translateX(-50%) translateY(0);
  transition-delay: 0.25s;
  opacity: 0.75;
}

.sv-save-data_root {
  span {
    display: flex;
    flex-grow: 1;
  }

  .sv-action-bar {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.sv-save-data_root--shown.sv-save-data_success,
.sv-save-data_root--shown.sv-save-data_error {
  opacity: 1;
}

.sv-save-data_root.sv-save-data_root--with-buttons {
  padding: calcSize(2) calcSize(2) calcSize(2) calcSize(6);
}

.sv-save-data_root.sv-save-data_error {
  background-color: $red;
  color: $background;
  font-weight: 600;
  gap: calcSize(6);
}

.sv-save-data_root.sv-save-data_error .sv-save-data_button {
  font-weight: 600;
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);
  height: calcSize(5);
  color: #ffffff;
  background-color: $red;
  border: calcSize(0.25) solid #ffffff;
  border-radius: calcCornerRadius(1.5);
  padding: calcSize(1) calcSize(3);
  display: flex;
  align-items: center;
}

.sv-save-data_root.sv-save-data_error .sv-save-data_button:hover,
.sv-save-data_root.sv-save-data_error .sv-save-data_button:focus {
  color: $red;
  background-color: $background;
}

.sv-save-data_root.sv-save-data_success {
  background-color: $primary;
  color: #ffffff;
  font-weight: 600;
}